import Keycloak from "keycloak-js";
import { registerApplication, start, navigateToUrl } from "single-spa";
import { constructApplications, constructRoutes, constructLayoutEngine } from "single-spa-layout";
import "./global.css?modules=false";
import "./assets/fonts/fonts.css?modules=false";
import { ApolloClient, InMemoryCache, ApolloProvider, gql, ApolloLink, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const keycloak = new Keycloak({
    url: `${process.env.KEYCLOAK_URL}`,
    realm: process.env.REALM,
    clientId: "personal-office-front",
});

keycloak
    .init({
        onLoad: "check-sso",
    })
    .then(async (authenticated) => {
        if (!authenticated) {
            window.location.href = keycloak.createLoginUrl();
        } else {
            const authLink = setContext((_, { headers }) => {
                return {
                    headers: {
                        ...headers,
                        authorization: keycloak.token ? `Bearer ${keycloak.token}` : "",
                    },
                };
            });

            const client = new ApolloClient({
                link: ApolloLink.from([authLink, createHttpLink({ uri: "/graphql/root" })]),
                cache: new InMemoryCache(),
            });

            const data = await init(client);

            let userInfo = data.user;
            let roles = data.roles;
            let myApps = data.apps;
            let teams = data.teams;

            let routesJson = {
                routes: [],
            };
            myApps.forEach((item) => {
                routesJson.routes.push({
                    type: "route",
                    path: item.application.route,
                    routes: [{ type: "application", name: item.application.name }],
                });
            });

            const routes = constructRoutes({
                routes: [
                    {
                        type: "div",
                        attrs: [
                            {
                                name: "class",
                                value: "main",
                            },
                        ],
                        routes: [
                            { type: "application", name: "@rapporto/side-menu" },
                            {
                                type: "div",
                                attrs: [
                                    {
                                        name: "class",
                                        value: "content",
                                    },
                                ],
                                routes: routesJson.routes,
                            },
                        ],
                    },
                ],
                redirects: {
                    "/": "/spam",
                },
            });

            const applications = constructApplications({
                routes,
                loadApp({ name }) {
                    let app = myApps.find((item) => item.application.name == name)?.application;
                    let path = process.env.isLocal && app ? app.bundle : name;
                    if (!process.env.isLocal) {
                        path = app ? app.bundle : name;
                    }
                    if (process.env.isLocal && app?.name == "@rapporto/spam") {
                        path = "http://localhost:9001/rapporto-spam.js";
                    }
                    if (process.env.isLocal && app?.name == "@rapporto/report") {
                        path = "http://localhost:9005/rapporto-report.js";
                    }
                    if (process.env.isLocal && app?.name == "@rapporto/user-manager") {
                        path = "http://localhost:9008/rapporto-roles-manager.js";
                    }
                    if (process.env.isLocal && app?.name == "@rapporto/settings") {
                        path = "http://localhost:9006/rapporto-settings.js";
                    }
                    if (process.env.isLocal && app?.name == "@rapporto/report") {
                        path = "http://localhost:9005/rapporto-report.js";
                    }
                    return System.import(path);
                },
            });
            const layoutEngine = constructLayoutEngine({ routes, applications });

            layoutEngine.activate();

            applications.forEach((item) => {
                registerApplication({
                    name: item.name,
                    activeWhen: item.activeWhen,
                    app: item.app,
                    customProps:
                        item.name == "@rapporto/side-menu"
                            ? {
                                  apps: myApps.map((item) => item.application),
                                  username: userInfo.name,
                                  roles,
                                  teams,
                                  userInfo,
                              }
                            : {
                                  roles,
                                  teams,
                                  userInfo,
                              },
                });
            });

            localStorage.setItem("accessToken", keycloak.token);
            localStorage.setItem("refreshToken", keycloak.refreshToken);
            start({ urlRerouteOnly: false });
            window.addEventListener("logout", () => {
                keycloak.logout();
            });
            window.addEventListener("navigateTo", (event: any) => {
                navigateToUrl(event.detail.url);
            });
        }
    })
    .catch((e) => {
        // window.location.href = keycloak.createLoginUrl()
        console.log(e);
        console.log(keycloak.authenticated, keycloak.createLoginUrl());
    });

async function init(client) {
    try {
        let response = await client.query({
            query: gql`
                query GetUser {
                    user {
                        id
                        email
                        firstName
                        lastName
                        enabled
                        status
                    }
                    teams {
                        id
                        name
                        companyId
                        status
                        restClientId
                        mobicontPartnerId
                        mobicontUserId
                        mobicontClusterId
                    }
                    roles
                    apps {
                        roles {
                            applicationId
                            description
                            name
                            id
                            protected
                            serviceId
                        }
                        application {
                            active
                            baseRoleTitle
                            bundle
                            displayName
                            description
                            icon
                            id
                            name
                            route
                            teamRole
                            uiBottom
                            uiOrder
                        }
                    }
                }
            `,
        });

        return response.data;
    } catch (e) {
        return [];
    }
}

keycloak.onTokenExpired = () => {
    console.log("token expired", keycloak.token);
    keycloak
        .updateToken(1)
        .then(() => {
            console.log("update token");
            localStorage.setItem("accessToken", keycloak.token);
            localStorage.setItem("refreshToken", keycloak.refreshToken);
        })
        .catch((e) => {
            console.log(e);
            window.location.href = keycloak.createLoginUrl();
        });
};
