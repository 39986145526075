// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./gteestiprodisplay_regular.otf";
import ___CSS_LOADER_URL_IMPORT_1___ from "./gteestiprodisplay_bold.otf";
import ___CSS_LOADER_URL_IMPORT_2___ from "./gteestiprodisplay_medium.otf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, " @font-face {\n     font-family: 'GT Eesti Pro Display';\n     src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n     font-weight: normal;\n     font-style: normal;\n }\n\n @font-face {\n     font-family: \"GT Eesti Pro Display\";\n     src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n     font-weight: bold;\n     font-style: normal;\n }\n\n\n @font-face {\n     font-family: \"GT Eesti Pro Display\";\n     src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n     font-weight: 500;\n     font-style: normal;\n }", "",{"version":3,"sources":["webpack://./src/assets/fonts/fonts.css"],"names":[],"mappings":"CAAC;KACI,mCAAmC;KACnC,4CAAyC;KACzC,mBAAmB;KACnB,kBAAkB;CACtB;;CAEA;KACI,mCAAmC;KACnC,4CAAsC;KACtC,iBAAiB;KACjB,kBAAkB;CACtB;;;CAGA;KACI,mCAAmC;KACnC,4CAAwC;KACxC,gBAAgB;KAChB,kBAAkB;CACtB","sourcesContent":[" @font-face {\n     font-family: 'GT Eesti Pro Display';\n     src: url('gteestiprodisplay_regular.otf');\n     font-weight: normal;\n     font-style: normal;\n }\n\n @font-face {\n     font-family: \"GT Eesti Pro Display\";\n     src: url('gteestiprodisplay_bold.otf');\n     font-weight: bold;\n     font-style: normal;\n }\n\n\n @font-face {\n     font-family: \"GT Eesti Pro Display\";\n     src: url('gteestiprodisplay_medium.otf');\n     font-weight: 500;\n     font-style: normal;\n }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
